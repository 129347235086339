<template>
  <div class="app-onboarding">
    <div class="wrapper app-onboarding__content-wrapper">
      <img class="app-onboarding__logo" src="@/assets/images/logo-vertical-transparent-new.svg">
      <div
        class="container"
        :class="{ 'app-onboarding__container-flex': containerFlex }"
      >
        <div class="col-xl-4 col-lg-5 col-md-6 mx-auto mb-3">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    containerFlex: Boolean,
  },
};
</script>
