<template>
  <div v-if="viewAs" class="view-as">
    <div class="view-as__container">
      <p class="view-as__user">
        {{ fio ? fio : profile.login }} <span v-if="profile.staffType">({{ staffTypeLabel }})</span> - {{ profile.id }}
      </p>
      <ui-button
        type="primary"
        class="view-as__button px-5"
        size="sm"
        @click="logout"
      >
        Done
      </ui-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('auth', [
      'viewAs',
    ]),
    ...mapState('user', [
      'profile',
      'location',
    ]),
    ...mapGetters('user', [
      'fio',
    ]),
    staffTypeLabel() {
      return this.$getStaffTypeName(this.profile.staffType);
    },
  },

  async mounted() {
    try {
      await this.getStaffTypes();
    } catch (e) {
      this.$showServerError(e);
    }
  },

  methods: {
    ...mapActions('handbook', [
      'getStaffTypes',
    ]),
    logout() {
      this.$router.push({ name: 'view-as-logout' });
    },
  },
};
</script>
