<template>
  <div class="bottom-sheet-polling">
    <ui-loading
      inline
      class="bottom-sheet-polling__spinner"
    />
    <div class="bottom-sheet-polling__text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomSheetPolling',
};
</script>
