<template>
  <div class="document document-distributions">
    <h1 v-if="!hideTitle" class="title">{{ $t('distributionsRules.title') }}</h1>
    <div class="document__content">
      <p v-html="$t('distributionsRules.block[0]')" class="document-black"></p>
      <p v-if="schemeText">{{ schemeText }}</p>
      <p v-html="$t('distributionsRules.block[1]')"></p>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    hideTitle: Boolean,
  },

  computed: {
    schemeText() {
      return this.$store.state.user.profile.distributionSchemes?.text;
    },
  },
};
</script>
