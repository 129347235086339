<template>
  <ui-navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" class="btn btn-icon btn-round" @click="minimizeSidebar">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle">
        <ui-navbar-toggler
          @click.native="toggleSidebar"
        ></ui-navbar-toggler>
      </div>
      <span class="navbar-brand">{{ $t($route.meta.title) }}</span>
      <locale class="navbar-locale" />
      <img src="@/assets/images/logo-vertical-transparent.svg" alt="" class="navbar-logo" />
    </div>

    <template slot="toggle-button">
      <div class="d-none"></div>
    </template>
  </ui-navbar>
</template>

<script>
import { mapMutations } from 'vuex';
import Locale from '@/components/Locale.vue';

export default {

  components: {
    Locale,
  },

  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
    };
  },

  methods: {
    ...mapMutations('sidebar', {
      toggleSidebar: 'toggle',
      hideSidebar: 'hide',
    }),

    minimizeSidebar() {
      document.body.classList.toggle('sidebar-mini');
      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(() => {
        window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(() => {
        clearInterval(simulateWindowResize);
      }, 1000);
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
  },
};
</script>

<style scoped>
.nav-link {
  cursor: pointer;
}
</style>
