var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-modal',_vm._g(_vm._b({staticClass:"bank-details-modal",attrs:{"show":_vm.show,"closable":_vm.modalClosable,"is-loading":_vm.modalLoading,"no-footer":""},on:{"before-open":_vm.beforeOpen},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"bank-details-modal__content"},[_c('div',{staticClass:"bank-details-modal__header"},[_c('div',{staticClass:"bank-details-modal__title"},[_vm._v(" "+_vm._s(_vm.$t('bankDetailsModal.title'))+" ")])]),_c('p',{staticClass:"bank-details-modal__message"},[_vm._v(" "+_vm._s(_vm.$t('bankDetailsModal.message'))+" ")]),(!_vm.showBankDetailsForm)?[_c('ui-icon',{staticClass:"bank-details-modal__icon",attrs:{"name":"filling-form","size":100}}),_c('div',{staticClass:"bank-details-modal__footer"},[_c('ui-button',{staticClass:"bank-details-modal__btn",attrs:{"round":"","type":"primary","native-type":"button"},on:{"click":_vm.openBankDetailsForm}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.ok'))+" ")])],1)]:[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('div',{staticClass:"bank-details-modal__form"},[_c('id-info-photos',{staticClass:"bank-details-modal__photos",attrs:{"validation-error":_vm.photoValidationError,"only-front":""}},[_c('id-photo-uploader',{attrs:{"user-id":_vm.profile.id},on:{"set-image-id":_vm.setImageId}})],1),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('bankDetailsModal.form.firstName'),"rules":"required|min:2|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('bankDetailsModal.form.firstName'),"maxlength":"64","minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('bankDetailsModal.form.lastName'),"rules":"required|min:2|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('bankDetailsModal.form.lastName'),"maxlength":"64","minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('bankDetailsModal.form.dateOfBirth'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-date-birth',{attrs:{"required":"","error":errors[0]},model:{value:(_vm.form.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.form, "dateOfBirth", $$v)},expression:"form.dateOfBirth"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('bankDetailsModal.form.nationality'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.nationalities,"label":_vm.$t('bankDetailsModal.form.nationality'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"id","filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.nationalityId),callback:function ($$v) {_vm.$set(_vm.form, "nationalityId", $$v)},expression:"form.nationalityId"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('bankDetailsModal.form.streetAddress'),"rules":"required|min:2|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('bankDetailsModal.form.streetAddress'),"maxlength":"256","minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.streetAddress),callback:function ($$v) {_vm.$set(_vm.form, "streetAddress", $$v)},expression:"form.streetAddress"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('bankDetailsModal.form.city'),"rules":"required|min:2|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('bankDetailsModal.form.city'),"maxlength":"256","minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('bankDetailsModal.form.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('bankDetailsModal.form.email'),"required":"","error":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('bankDetailsModal.form.bankName'),"rules":"required|min:2|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('bankDetailsModal.form.bankName'),"maxlength":"256","minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.bankName),callback:function ($$v) {_vm.$set(_vm.form, "bankName", $$v)},expression:"form.bankName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('bankDetailsModal.form.iban'),"rules":"required|min:2|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('bankDetailsModal.form.iban'),"maxlength":"256","minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.IBAN),callback:function ($$v) {_vm.$set(_vm.form, "IBAN", $$v)},expression:"form.IBAN"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('bankDetailsModal.form.swift'),"rules":"required|min:2|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('bankDetailsModal.form.swift'),"maxlength":"256","minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.SWIFT),callback:function ($$v) {_vm.$set(_vm.form, "SWIFT", $$v)},expression:"form.SWIFT"}})]}}],null,true)})],1),_c('div',{staticClass:"bank-details-modal__explanation"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('bankDetailsModal.explanation'))+" ")])]),_c('div',{staticClass:"bank-details-modal__footer"},[_c('ui-button',{staticClass:"bank-details-modal__btn",attrs:{"disabled":_vm.loading.form,"round":"","type":"primary","native-type":"button","is-loading":_vm.loading.form},on:{"click":function($event){return _vm.submit(validate, close)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.ok'))+" ")])],1)]}}],null,true)})]],2)]}}])},'ui-modal',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }