<template>
  <div>
    <img src="@/assets/images/credit-card/credit-card-secure-1.png" alt="secure">
    <img src="@/assets/images/credit-card/credit-card-secure-2.png" alt="secure">
    <img src="@/assets/images/credit-card/credit-card-secure-3.png" alt="secure">
  </div>
</template>

<script>
export default {
  name: 'CheckoutCreditSecurity',
};
</script>
