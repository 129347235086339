<template>
  <div
    class="credit-card"
    :class="{ 'credit-card_horizontal': horizontal }"
  >
    <div v-if="!suffix" class="credit-card__plug">
      <div class="credit-card-content__ellipse_top" />
      <div class="credit-card-content__ellipse_bottom" />
      <div class="credit-card__plug-text">
        {{ $t('creditCard.empty') }}
      </div>
      <img
        src="@/assets/images/credit-card/credit-card-chip.png"
        class="credit-card-content__chip"
      >
    </div>
    <div v-else class="credit-card-content">
      <div
        v-if="paymentLogo"
        class="credit-card-content__logo"
      >
        <ui-icon
          :name="paymentLogo"
          width="60px"
          height="50px"
        />
      </div>
      <div class="credit-card-content__ellipse_top" />
      <div class="credit-card-content__ellipse_bottom" />
      <div class="credit-card-content__info">
        <div class="credit-card-content__title">
          {{ $t('creditCard.label') }}
        </div>
        <div class="credit-card-content__number">
          <div>● ● ● ●</div>
          <div class="credit-card-content__suffix">{{ suffix }}</div>
        </div>
        <img
          src="@/assets/images/credit-card/credit-card-chip.png"
          class="credit-card-content__chip"
        >
      </div>
    </div>
  </div>
</template>

<script>
import paymentCardList from '@/config/paymentCards';

export default {
  name: 'CreditCard',

  props: {
    suffix: {
      type: String,
      default: '',
    },
    paymentSystem: {
      type: String,
      default: '',
    },
    horizontal: Boolean,
  },

  computed: {
    paymentLogo() {
      return paymentCardList[this.paymentSystem]?.image;
    },
  },
};
</script>
