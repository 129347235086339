<template>
  <div id="app" :class="{'nav-open': showSidebar, 'design': true }">
    <notifications
      transition-name="notification-list"
      transition-mode="out-in"
    />
    <router-view name="header" />
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view />
    </transition>
    <router-view name="footer" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {

  computed: {
    ...mapState('sidebar', {
      showSidebar: 'show',
    }),
  },

  methods: {
    ...mapActions('handbook', [
      'getStaffTypes',
    ]),
  },

  async mounted() {
    try {
      await this.getStaffTypes({ force: true });
    } catch (e) {
      this.$showServerError(e);
    }
  },
};
</script>

<style>
  .notifications {
    z-index: 2030;
    position: relative;
  }

  .el-range-separator {
    width: auto!important;
  }
</style>
