<template>
  <div class="content">
    <ui-modal
      v-if="showTermsModal || keepTermsOpen"
      show
      wide
      :closable="false"
      :scrollable="false"
      class="terms-modal"
      :opaque="hideOldDesign"
    >
      <div class="conditions">
        <div class="conditions__header">
          <h5 class="conditions__title">
            {{ tabTitle }}
          </h5>
        </div>

        <div class="conditions__container">
          <div ref="conditions" class="conditions__content">
            <transition name="conditions__fade" mode="out-in">
              <component
                v-if="tabComponent"
                :is="tabComponent"
                hide-title
              />
            </transition>
          </div>
        </div>
      </div>

      <template #footer>
        <div class="conditions__footer">
          <new-button
            type="secondary"
            size="sm"
            :is-loading="loading.decline"
            @click="decline"
          >
            {{ declineButtonTitle }}
          </new-button>
          <new-button
            :type="buttonType"
            size="sm"
            :is-loading="loading.accept"
            @click="apply"
          >
            {{ acceptButtonTitle }}
          </new-button>
        </div>
      </template>
    </ui-modal>

    <transition v-else name="fade" mode="out-in">
      <router-view name="default"></router-view>
    </transition>

    <vue-bottom-sheet ref="vbsDeclineTerms">
      <bottom-sheet-confirmation
        confirm-text="Cancel"
        @confirm="continueHandler"
      >
        Are you going to decline<br/>
        Terms and Conditions<br/>
        and exit the app?
      </bottom-sheet-confirmation>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import NymTerms from '@/components/Nym/NymTerms.vue';
import BottomSheetConfirmation from '@/components/BottomSheet/BottomSheetConfirmation.vue';
import TermsAndConditionsGb from './TermsAndConditionsGb.vue';
import TermsAndConditionsDe from './TermsAndConditionsDe.vue';
import TermsAndConditionsUae from './TermsAndConditionsUae.vue';
import PrivacyPolicyGb from './PrivacyPolicyGb.vue';
import PrivacyPolicyDe from './PrivacyPolicyDe.vue';
import PrivacyPolicyUae from './PrivacyPolicyUae.vue';
import DistributionsRules from './DistributionsRules.vue';

export default {

  components: {
    BottomSheetConfirmation,
    TermsAndConditionsGb,
    TermsAndConditionsDe,
    TermsAndConditionsUae,
    PrivacyPolicyGb,
    PrivacyPolicyDe,
    PrivacyPolicyUae,
    DistributionsRules,
  },

  data: () => ({
    loading: {
      accept: false,
      decline: false,
    },
    tabSelected: null,
    keepTermsOpen: false,
  }),

  computed: {
    ...mapGetters('auth', [
      'isLogged',
      'isStaff',
    ]),
    ...mapState('user', [
      'profile',
      'location',
      'isTermAccepted',
      'isDistributionRulesAccepted',
      'isAcceptedNymTerms',
    ]),
    ...mapGetters('user', [
      'isCommon',
      'needChangeTemporaryPassword',
      'needRedirectFromTermsToPasswordChanging',
      'needNymOnboarding',
      'needOnboarding',
    ]),
    hideOldDesign() {
      return this.needRedirectFromTermsToPasswordChanging || this.profile.isNeedNymOnboarding;
    },
    buttonType() {
      return this.hideOldDesign ? 'primary' : 'old-primary';
    },
    termsComponent() {
      if (this.isUk || this.isSwitzerland) {
        return TermsAndConditionsGb;
      }

      return this.isUAE
        ? TermsAndConditionsUae
        : TermsAndConditionsDe;
    },
    privacyComponent() {
      if (this.isUk || this.isSwitzerland) {
        return PrivacyPolicyGb;
      }

      return this.isUAE
        ? PrivacyPolicyUae
        : PrivacyPolicyDe;
    },
    termsTabs() {
      const tabs = [];

      if (!this.isTermAccepted) {
        tabs.push(
          {
            id: 'terms',
            group: 'terms-privacy',
            accepted: false,
            callback: this.applyTerms,
            component: this.termsComponent,
          },
          {
            id: 'privacy',
            group: 'terms-privacy',
            accepted: false,
            callback: this.applyTerms,
            component: this.privacyComponent,
          },
        );
      }

      if (this.showDistributionsRules && !this.isDistributionRulesAccepted) {
        tabs.push({
          id: 'distributions',
          group: 'distributions',
          accepted: false,
          callback: this.applyDistributions,
          component: DistributionsRules,
        });
      }

      if (this.profile.enableNymCard && !this.isAcceptedNymTerms) {
        tabs.push({
          id: 'nym-card',
          group: 'nym-card',
          accepted: false,
          callback: this.applyNymTerms,
          component: NymTerms,
        });
      }

      return tabs;
    },
    showDistributionsRules() {
      return (this.location.country === 'de' || this.location.country === 'ae')
        && this.isStaff
        && this.isCommon;
    },
    showTermsModal() {
      return this.isLogged && this.termsTabs.length;
    },
    tab() {
      return this.termsTabs?.[this.tabIndex] || null;
    },
    tabIndex() {
      return this.termsTabs.findIndex(({ id }) => id === this.tabSelected);
    },
    tabTitle() {
      if (this.tabSelected === 'terms') {
        return this.isUk || this.isSwitzerland || this.isUAE
          ? 'Platform Terms and conditions'
          : 'Nutzungsbedingungen';
      }

      if (this.tabSelected === 'privacy') {
        return this.isUk || this.isSwitzerland || this.isUAE
          ? 'Privacy Policy'
          : 'Datenschutzerklärung';
      }

      if (this.tabSelected === 'distributions') {
        return 'Aufteilung';
      }

      if (this.tabSelected === 'nym-card') {
        return 'Cardholders Terms and Conditions';
      }

      return '';
    },
    tabComponent() {
      return this.tab?.component;
    },
    acceptButtonTitle() {
      return this.isUk || this.isSwitzerland || this.isUAE
        ? 'Accept'
        : 'Akzeptieren';
    },
    declineButtonTitle() {
      return this.isUk || this.isSwitzerland || this.isUAE
        ? 'Decline'
        : 'Ablehnen';
    },
    isUk() {
      return this.location.country === 'gb';
    },
    isUAE() {
      return this.location.country === 'ae';
    },
    isSwitzerland() {
      return this.location.country === 'ch';
    },
  },

  watch: {
    tabSelected() {
      this.$nextTick(() => {
        if (this.$refs.conditions) {
          this.$refs.conditions.scrollTo(0, 0);
        }
      });
    },
  },

  mounted() {
    this.tabSelected = this.termsTabs?.[0]?.id;
  },

  methods: {
    ...mapActions({
      applyTerms: 'user/applyTerms',
      applyDistributions: 'user/applyDistributions',
      applyNymTerms: 'user/applyNymTerms',
      logout: 'auth/logout',
    }),
    async apply() {
      const tab = this.termsTabs.find(({ id }) => id === this.tabSelected);

      if (tab) {
        tab.accepted = true;
      }

      const nearest = this.termsTabs.find((item) => {
        return item.id !== tab.id
          && item.group === tab.group
          && !item.accepted;
      });

      // Switch to next group tab.
      if (nearest) {
        this.tabSelected = nearest.id;

        return;
      }

      // Accept groupped tabs.
      if (this.loading.accept) {
        return;
      }

      this.loading.accept = true;

      try {
        this.keepTermsOpen = true;

        await tab.callback();

        if (this.showTermsModal) {
          this.tabSelected = this.termsTabs.find(({ accepted }) => !accepted)?.id;
        } else {
          if (!this.needChangeTemporaryPassword) {
            this.$showSuccess();
          }

          if (this.needNymOnboarding || this.needOnboarding) {
            await this.$router.push({ name: 'onboarding' });
          }
        }
      } catch (e) {
        this.$showServerError(e);

        tab.accepted = false;
      } finally {
        this.loading.accept = false;
        this.keepTermsOpen = false;
      }
    },
    decline() {
      this.$refs.vbsDeclineTerms.open();
    },
    continueHandler() {
      this.$refs.vbsDeclineTerms.close();
    },
  },
};
</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .15s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */
  {
    opacity: 0
  }
  .terms-modal .modal {
    z-index: 1051;
  }
  .terms-modal .modal-dialog {
    max-width: 800px;
  }
</style>
