var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var reset = ref.reset;
var handleSubmit = ref.handleSubmit;
return [_c('ui-modal',{ref:"modal",staticClass:"checkout-card-confirm",attrs:{"show":_vm.show,"is-loading":_vm.loading},on:{"before-open":function($event){return _vm.beforeOpen(reset)},"closed":_vm.closed,"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{staticClass:"checkout-card-confirm__title"},[_vm._v(" "+_vm._s(_vm.$t('checkout.title'))+" ")])]},proxy:true},{key:"footer",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"checkout-card-confirm__actions"},[_c('ui-button',{staticClass:"checkout-card-confirm__button",attrs:{"size":"sm","round":"","outline":""},on:{"click":close}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.cancel'))+" ")]),_c('ui-button',{staticClass:"checkout-card-confirm__button",class:{
            'checkout-card-confirm__button_primary': _vm.isOkButtonPrimary,
          },attrs:{"size":"sm","round":"","outline":""},on:{"click":function($event){handleSubmit(function () { return _vm.apply(close); })}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.ok'))+" ")])],1)]}}],null,true)},[_c('div',{staticClass:"checkout-card-confirm__body"},[_c('p',{staticClass:"checkout-card-confirm__info-title"},[_vm._v(" "+_vm._s(_vm.$t('checkout.subtitle'))+" ")]),_c('div',{staticClass:"checkout-card-confirm__code-input"},[_c('validation-provider',{attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('ui-otp-input',{ref:"otpInput",attrs:{"input-count":_vm.inputCount,"is-danger":errors[0] || _vm.otpCodeInvalid || _vm.otpCodeExpired,"error":errors[0] || _vm.otpCodeInvalid},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}],null,true)})],1),(_vm.otpCodeExpired)?_c('div',{staticClass:"checkout-card-confirm__expired-block"},[_c('span',{staticClass:"checkout-card-confirm__expired-message"},[_vm._v(" "+_vm._s(_vm.otpCodeExpired)+" ")]),_c('ui-button',{staticClass:"checkout-card-confirm__expired-link",attrs:{"type":"primary","link":""},on:{"click":function($event){return _vm.create(reset)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.resendVerificationCode'))+" ")])],1):(_vm.showResend)?_c('ui-button',{attrs:{"type":"primary","link":"","disabled":_vm.showTimer},on:{"click":function($event){return _vm.create(reset)}}},[(_vm.showTimer)?_c('span',{staticClass:"checkout-card-confirm__timer"},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.resendVerificationCode'))+" ("+_vm._s(_vm.timer)+") ")]):_c('span',{staticClass:"checkout-card-confirm__code-link"},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.resendVerificationCode'))+" ")])]):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }