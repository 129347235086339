<template>
  <div class="checkout-card-info">
    <div class="checkout-card-info__content">
      <template v-if="onboarding">
        <credit-card
          horizontal
          :suffix="cardSuffix"
          :payment-system="paymentSystem"
          class="checkout-card-info__card checkout-card-info__card_mobile"
        />
        <checkout-credit-security
          class="checkout-card-info__secure-info checkout-card-info__secure-info_mobile"
        />
      </template>
      <div class="checkout-card-info__header">
        <div
          v-if="showCardType"
          class="checkout-card-info__card-type"
        >
          <div
            class="checkout-card-info__card-type-radio"
            :class="{
              'checkout-card-info__card-type-radio--center': onboarding,
            }"
          >
            <ui-toggle
              :selected-value="selectedCardType"
              v-protected
              :elements="availableCardTypeList"
              @select="setSelectedCardType"
            />
          </div>
        </div>
        <div class="checkout-card-info__additional-form-fields">
          <validation-provider
            ref="cardholderName"
            tag="div"
            :name="$t('form.labels.cardholderName')"
            class="checkout-card-info__cardholder-name-input input-group-focus is-focused"
            rules="required|cardholderName"
            mode="lazy"
            v-slot="{ errors }"
          >
            <ui-input
              v-model="cardholderName"
              v-protected
              :label="`${$t('form.labels.cardholderName')} *`"
              :is-filled="true"
              :error="errors[0]"
            />
          </validation-provider>
          <checkout-credit-security
            class="checkout-card-info__secure-info checkout-card-info__secure-info_desktop"
          />
        </div>
      </div>
      <form
        method="POST"
        action="https://merchant.com/charge-card"
        class="checkout-card-info__form"
        @submit="onSubmit"
      >
        <div class="checkout-card-info__frame-wrapper">
          <div v-protected>
            <div class="checkout-card-info__frame-input-label">
              {{ $t('form.labels.cardNumber') }} *
            </div>
            <div class="checkout-card-info__frame-input">
              <div class="card-number-frame">
                <!-- card number will be added here -->
              </div>
            </div>
          </div>
          <div class="checkout-card-info__date-cvv">
            <div v-protected>
              <div class="checkout-card-info__frame-input-label">
                {{ $t('form.labels.cardExpiryDate') }} *
              </div>
              <div class="checkout-card-info__frame-input">
                <div class="expiry-date-frame">
                  <!-- expiry date will be added here -->
                </div>
              </div>
            </div>
            <div v-protected>
              <div class="checkout-card-info__frame-input-label">
                {{ $t('form.labels.cardCvc') }} *
              </div>
              <div class="checkout-card-info__frame-input">
                <div class="cvv-frame">
                  <!-- cvv frame will be added here -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="!onboarding">
          <checkout-credit-security
            class="checkout-card-info__secure-info checkout-card-info__secure-info_mobile"
          />
          <credit-card
            :suffix="cardSuffix"
            :payment-system="paymentSystem"
            class="checkout-card-info__card checkout-card-info__card_mobile"
          />
        </template>

        <div class="checkout-card-info__footer">
          <ui-button
            v-if="!loading.init"
            v-protected
            action
            native-type="submit"
            type="primary"
            :disabled="disabled || !isProfileFilled || !isCardholderNameValid"
            :is-loading="loading.form"
          >
            {{ submitButtonText }}
          </ui-button>
          <checkout-card-status
            v-if="cardStatus"
            :status="cardStatus"
          />
        </div>
      </form>
    </div>

    <credit-card
      :suffix="cardSuffix"
      :payment-system="paymentSystem"
      class="checkout-card-info__card checkout-card-info__card_desktop"
    />

    <checkout-card-confirm
      v-model="confirm"
      :user-id="userId"
      :card="currentCard"
      :card-type="selectedCardType"
      @confirmed="onConfirm"
      @failed="onFailed"
      @closed="onClosed"
    />

    <ui-modal-status
      v-model="modalStatus.show"
      :key="modalStatus.key"
      :type="modalStatus.type"
      :icon="modalStatus.icon"
      :icon-size="modalStatus.iconSize"
      :title="modalStatus.title"
      :message="modalStatus.message"
      :no-button="modalStatus.noButton"
      @closed="invalidCardModalClose"
    />
    <vue-bottom-sheet
      ref="invalidCardAlert"
      @closed="invalidCardModalClose"
    >
      <payout-card-error-alert
        @try-again="closeCardErrorAlert"
        @skip="skipCardFillingHandler"
      />
    </vue-bottom-sheet>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { uniqueId } from 'lodash';
import api from '@/api';
import resetMixin from '@/mixins/reset-mixin';
import CreditCard from '@/components/CreditCard.vue';
import CARD_TYPES from '@/config/cardTypes';
import PayoutCardErrorAlert from '@/components/Onboarding/Errors/PayoutCardErrorAlert.vue';
import { PAYMENT_SYSTEM } from '@/config/paymentSystems';
import CheckoutCreditSecurity from './CheckoutCreditSecurity.vue';
import CheckoutCardConfirm from './CheckoutCardConfirm.vue';
import CheckoutCardStatus from './CheckoutCardStatus.vue';

export default {
  name: 'CheckoutCardInfo',

  components: {
    PayoutCardErrorAlert,
    CreditCard,
    CheckoutCreditSecurity,
    CheckoutCardConfirm,
    CheckoutCardStatus,
  },

  props: {
    userId: {
      type: String,
    },
    onboarding: Boolean,
  },

  mixins: [
    resetMixin(() => ({
      modalStatus: {
        key: '',
        show: false,
        type: 'success',
        icon: '',
        iconSize: '',
        title: '',
        message: '',
        noButton: false,
      },
    })),
  ],

  data: () => ({
    loading: {
      init: true,
      form: false,
    },
    cardholderName: '',
    confirm: false,
    disabled: true,
    frames: null,
    previousCard: null,
  }),

  computed: {
    ...mapState({
      location: (state) => state.user.location,
      checkoutPublicKey: (state) => state.payouts.checkoutPublicKey,
      currentCard: (state) => state.payouts.currentCard,
      selectedCardType: (state) => state.payouts.selectedCardType,
    }),
    ...mapGetters({
      isOwner: 'auth/isOwner',
      isCommon: 'user/isCommon',
      isProfileFilled: 'user/isProfileFilled',
      isUk: 'user/isUk',
      availableCardTypeList: 'payouts/availableCardTypeList',
      isSelfReportingTypeLocation: 'user/isSelfReportingTypeLocation',
    }),
    submitButtonText() {
      return this.cardSuffix ? this.$t('form.buttons.updateCard') : this.$t('form.buttons.addCard');
    },
    showCardType() {
      return this.availableCardTypeList.length > 1;
    },
    cardSuffix() {
      return this.currentCard?.suffix || '';
    },
    cardStatus() {
      if (!this.isProfileFilled) {
        return 'NO_PROFILE_DATA';
      }

      return this.currentCard?.status || '';
    },
    paymentSystem() {
      return this.currentCard?.paymentSystem || '';
    },
    isCardholderNameValid() {
      return this.$refs.cardholderName.flags.valid;
    },
  },

  watch: {
    async selectedCardType(type, oldType) {
      if (oldType) {
        this.refreshFrames();
      }

      await this.loadCurrentCard();
      this.$emit('card-loaded');
    },
  },

  created() {
    this.setSelectedCardType(this.availableCardTypeList[0]?.value);
  },

  mounted() {
    this.loadFrames();
  },

  methods: {
    ...mapActions('payouts', [
      'setCurrentCard',
      'setSelectedCardType',
    ]),
    showStatusModal(modalData) {
      this.reset();

      this.modalStatus = {
        key: uniqueId('modal-status_'),
        show: true,
        ...modalData,
      };
    },
    showInvalidCardModal(modalData) {
      this.showStatusModal({
        ...modalData,
      });
    },
    loadFrames() {
      if (typeof window.Frames !== 'undefined') {
        this.initFrames();
      } else {
        const script = document.createElement('script');

        script.src = 'https://cdn.checkout.com/js/framesv2.min.js';
        script.onload = () => {
          this.initFrames();
        };

        document.head.appendChild(script);
      }
    },
    initFrames(listen = true) {
      this.loading.init = false;
      this.frames = window.Frames;
      this.frames.init({
        publicKey: this.checkoutPublicKey,
        localization: {
          cardNumberPlaceholder: '0000 0000 0000 0000',
          expiryMonthPlaceholder: 'MM',
          expiryYearPlaceholder: 'YY',
          cvvPlaceholder: '• • •',
        },
        style: {
          base: {
            fontSize: '16px',
            color: 'black',
          },
          placeholder: {
            base: {
              color: '#C4C4C4',
            },
          },
        },
      });

      if (listen) {
        this.listenFrames();
      }
    },
    listenFrames() {
      this.frames.addEventHandler(this.frames.Events.CARD_VALIDATION_CHANGED, () => {
        this.disabled = !this.frames.isCardValid();
      });

      this.frames.addEventHandler(this.frames.Events.CARD_TOKENIZATION_FAILED, (error) => {
        console.log(error);
        this.frames.enableSubmitForm();
      });
    },
    async onSubmit(event) {
      event.preventDefault();

      this.loading.form = true;

      try {
        const form = event.target;
        const { token } = await this.frames.submitCard();
        const method = this.selectedCardType === CARD_TYPES.CORPORATE
          ? 'addCorporateCard'
          : 'addPersonalCard';
        const request = this.selectedCardType === CARD_TYPES.CORPORATE
          ? { workplaceId: this.location.id }
          : { userId: this.userId };

        this.cardholderName = this.cardholderName.trim();
        const splitCardholderName = this.cardholderName.replace(/\s/, '*').split('*');

        const firstName = splitCardholderName[0].toUpperCase();
        const lastName = splitCardholderName[1].toUpperCase();

        this.frames.addCardToken(form, token);

        const { data } = await api.checkout[method]({
          ...request,
          firstName,
          lastName,
          token,
          paymentProvider: PAYMENT_SYSTEM.CHECKOUT,
        });
        this.setCurrentCard(data);
        this.confirm = true;
      } catch (e) {
        if (e.response.data.errorCode === 422203) {
          if (this.onboarding) {
            this.$refs.invalidCardAlert.open();
          } else {
            this.showInvalidCardModal({
              type: 'warning',
              title: this.$t('payoutCard.invalid.title'),
              message: this.$t('payoutCard.invalid.message'),
            });
          }
        } else {
          this.$showServerError(e);
        }
      } finally {
        this.loading.form = false;
      }
    },
    async loadCurrentCard() {
      if (!this.selectedCardType) {
        return;
      }

      try {
        const method = this.selectedCardType === CARD_TYPES.CORPORATE
          ? 'getCorporateCard'
          : 'getPersonalCard';
        const request = this.selectedCardType === CARD_TYPES.CORPORATE
          ? this.location.id
          : this.userId;

        const { data } = await api.checkout[method](request);

        if (data?.status === 'BLOCKED') {
          this.showInvalidCardModal({
            icon: 'cards-error',
            iconSize: '100',
            title: this.$t('payoutCard.error.title'),
            message: this.$t('payoutCard.error.message'),
          });
        }

        this.$emit('card-status', data?.status);
        this.setCurrentCard(data);
      } catch (e) {
        this.setCurrentCard(null);
        if (e?.response?.status !== 404) {
          this.$showServerError(e);
        }
      }
    },
    onConfirm() {
      this.cardholderName = '';
      this.$refs.cardholderName.reset();
      this.loadCurrentCard();
      this.initFrames(false /* listenFrames */);
      this.$emit('confirmed');
    },
    refreshFrames() {
      this.cardholderName = '';
      this.$refs.cardholderName.reset();
      this.initFrames();
      this.frames.enableSubmitForm();
    },
    onClosed() {
      this.loadCurrentCard();
      this.refreshFrames();
    },
    invalidCardModalClose() {
      this.refreshFrames();
    },
    onFailed(payload) {
      this.showInvalidCardModal({
        ...payload,
      });
    },
    closeCardErrorAlert() {
      this.$refs.invalidCardAlert.close();
      this.refreshFrames();
    },
    skipCardFillingHandler() {
      this.$refs.invalidCardAlert.close();
      this.$emit('skip');
    },
  },
};
</script>
