<template>
  <div
    class="id-info-photos"
    :class="{ 'id-info-photos_only-front': onlyFront }"
  >
    <div class="id-info-photos__title id-info-photos__title_front">
      {{ $t('idInfoModal.photoUploader.front') }}
    </div>
    <div class="id-info-photos__title id-info-photos__title_back">
      {{ $t('idInfoModal.photoUploader.back') }}
    </div>
    <slot />
    <div
      class="id-info-photos__description"
      :class="{ 'id-info-photos__description_error': validationError }"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'IdInfoPhotos',

  props: {
    validationError: Boolean,
    onlyFront: Boolean,
  },

  computed: {
    description() {
      return this.onlyFront
        ? this.$t('idInfoModal.photoUploader.description')
        : this.$t('idInfoModal.photoUploader.multipleDescription');
    },
  },
};
</script>
