<template>
  <ui-modal
    :show="show"
    :closable="modalClosable"
    :is-loading="modalLoading"
    no-footer
    v-bind="$attrs"
    v-on="$listeners"
    class="bank-details-modal"
    v-slot="{ close }"
    @before-open="beforeOpen"
  >
    <div class="bank-details-modal__content">
      <div class="bank-details-modal__header">
        <div class="bank-details-modal__title">
          {{ $t('bankDetailsModal.title') }}
        </div>
      </div>
      <p class="bank-details-modal__message">
        {{ $t('bankDetailsModal.message') }}
      </p>
      <template v-if="!showBankDetailsForm">
        <ui-icon
          class="bank-details-modal__icon"
          name="filling-form"
          :size="100"
        />
        <div class="bank-details-modal__footer">
          <ui-button
            round
            type="primary"
            native-type="button"
            class="bank-details-modal__btn"
            @click="openBankDetailsForm"
          >
            {{ $t('form.buttons.ok') }}
          </ui-button>
        </div>
      </template>
      <template v-else>
        <validation-observer v-slot="{ validate }">
          <div class="bank-details-modal__form">
            <id-info-photos
              :validation-error="photoValidationError"
              only-front
              class="bank-details-modal__photos"
            >
              <id-photo-uploader
                :user-id="profile.id"
                @set-image-id="setImageId"
              />
            </id-info-photos>
            <validation-provider
              tag="div"
              :name="$t('bankDetailsModal.form.firstName')"
              rules="required|min:2|max:256"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.firstName"
                :label="$t('bankDetailsModal.form.firstName')"
                maxlength="64"
                minlength="2"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('bankDetailsModal.form.lastName')"
              rules="required|min:2|max:256"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.lastName"
                :label="$t('bankDetailsModal.form.lastName')"
                maxlength="64"
                minlength="2"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('bankDetailsModal.form.dateOfBirth')"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-date-birth
                v-model="form.dateOfBirth"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('bankDetailsModal.form.nationality')"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.nationalityId"
                :items="nationalities"
                :label="$t('bankDetailsModal.form.nationality')"
                :placeholder="$t('form.placeholders.select')"
                label-key="name"
                value-key="id"
                filterable
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('bankDetailsModal.form.streetAddress')"
              rules="required|min:2|max:256"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.streetAddress"
                :label="$t('bankDetailsModal.form.streetAddress')"
                maxlength="256"
                minlength="2"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('bankDetailsModal.form.city')"
              rules="required|min:2|max:256"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.city"
                :label="$t('bankDetailsModal.form.city')"
                maxlength="256"
                minlength="2"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('bankDetailsModal.form.email')"
              rules="required|email"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.email"
                :label="$t('bankDetailsModal.form.email')"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('bankDetailsModal.form.bankName')"
              rules="required|min:2|max:256"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.bankName"
                :label="$t('bankDetailsModal.form.bankName')"
                maxlength="256"
                minlength="2"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('bankDetailsModal.form.iban')"
              rules="required|min:2|max:256"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.IBAN"
                :label="$t('bankDetailsModal.form.iban')"
                maxlength="256"
                minlength="2"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('bankDetailsModal.form.swift')"
              rules="required|min:2|max:256"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.SWIFT"
                :label="$t('bankDetailsModal.form.swift')"
                maxlength="256"
                minlength="2"
                required
                :error="errors[0]"
              />
            </validation-provider>
          </div>
          <div class="bank-details-modal__explanation">
            <p>
              {{ $t('bankDetailsModal.explanation') }}
            </p>
          </div>
          <div class="bank-details-modal__footer">
            <ui-button
              :disabled="loading.form"
              round
              type="primary"
              native-type="button"
              class="bank-details-modal__btn"
              :is-loading="loading.form"
              @click="submit(validate, close)"
            >
              {{ $t('form.buttons.ok') }}
            </ui-button>
          </div>
        </validation-observer>
      </template>
    </div>
  </ui-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import api from '@/api';
import toSQLDate from '@/utils/toSQLDate';
import UiModal from '@/components/UI/Modal.vue';
import IdInfoPhotos from '@/components/IdInfo/IdInfoPhotos.vue';
import IdPhotoUploader from '@/components/IdInfo/IdPhotoUploader.vue';
import resetMixin from '@/mixins/reset-mixin';

export default {
  name: 'BankDetailsModal',

  components: {
    IdPhotoUploader,
    IdInfoPhotos,
    UiModal,
  },

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    show: Boolean,
  },

  mixins: [
    resetMixin(() => ({
      showBankDetailsForm: false,
      imageId: null,
      loading: {
        form: false,
        nationalities: false,
      },
      form: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        nationalityId: '',
        streetAddress: '',
        city: '',
        email: '',
        bankName: '',
        IBAN: '',
        SWIFT: '',
      },
      photoValidationError: false,
    })),
  ],

  data() {
    return {
      loaded: {
        nationalities: false,
      },
      nationalities: [],
    };
  },

  computed: {
    ...mapState('user', [
      'profile',
    ]),
    modalClosable() {
      return !this.modalLoading;
    },
    modalLoading() {
      return this.showBankDetailsForm && this.loading.nationalities;
    },
  },

  methods: {
    ...mapActions('user', [
      'loadUser',
    ]),
    async submit(validate, closeModal) {
      if (!this.imageId) {
        this.photoValidationError = true;
      }

      if (!await validate() || this.photoValidationError) {
        return;
      }

      this.loading.form = true;

      try {
        await api.user.sendBankDetails({
          id: this.profile.id,
          address: `${this.form.streetAddress}, ${this.form.city}`,
          dateOfBirth: toSQLDate(this.form.dateOfBirth),
          email: this.form.email,
          firstName: this.form.firstName,
          iban: this.form.IBAN,
          lastName: this.form.lastName,
          nationality: {
            id: this.form.nationalityId,
          },
          bankName: this.form.bankName,
          swiftOrBic: this.form.SWIFT,
          frontSideDocumentId: this.imageId,
        });

        await this.loadUser();
        closeModal();

        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.form = false;
      }
    },
    openBankDetailsForm() {
      this.showBankDetailsForm = true;
    },
    async loadAllCountries() {
      if (this.loaded.nationalities) {
        return;
      }

      this.loading.nationalities = true;

      try {
        const { data } = await api.handbook.getCountries();

        this.nationalities = data;
        this.loaded.nationalities = true;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading.nationalities = false;
      }
    },
    fillKnownFields() {
      this.form.firstName = this.profile.firstName || '';
      this.form.lastName = this.profile.lastName || '';
      this.form.email = this.profile.email || '';
      this.form.dateOfBirth = new Date(this.profile.dateOfBirth) || '';
    },
    setImageId(id) {
      this.imageId = id;
      this.photoValidationError = false;
    },
    async beforeOpen() {
      await this.loadAllCountries();

      this.reset();
      this.fillKnownFields();
    },
  },
};
</script>
