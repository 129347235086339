var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-modal',{staticClass:"id-info-modal",attrs:{"show":"","no-footer":""},on:{"closed":function($event){return _vm.setVisibilityRequestIdModal(false)}}},[_c('div',{staticClass:"id-info-modal__content"},[_c('div',{staticClass:"id-info-modal__header"},[_c('div',{staticClass:"id-info-modal__title"},[_vm._v(" "+_vm._s(_vm.$t('idInfoModal.title'))+" ")])]),_c('p',{staticClass:"id-info-modal__message"},[_vm._v(" "+_vm._s(_vm.$t('idInfoModal.message'))+" ")]),(!_vm.showIdInfoForm)?[_c('ui-icon',{staticClass:"id-info-modal__icon",attrs:{"name":"filling-form","size":100}}),_c('div',{staticClass:"id-info-modal__footer"},[_c('ui-button',{staticClass:"id-info-modal__btn",attrs:{"round":"","type":"primary","native-type":"button"},on:{"click":_vm.openIdInfoForm}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.ok'))+" ")])],1)]:[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('div',{staticClass:"id-info-modal__form"},[_c('id-info-photos',{attrs:{"validation-error":_vm.photoValidationError}},[_c('id-photo-uploader',{attrs:{"side":"FRONT","user-id":_vm.userId},on:{"set-image-id":function($event){return _vm.setImageId($event, 'front')}}}),_c('id-photo-uploader',{attrs:{"side":"BACK","user-id":_vm.userId},on:{"set-image-id":function($event){return _vm.setImageId($event, 'back')}}})],1),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('idInfoModal.form.fullLegalName'),"rules":"required|min:2|max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('idInfoModal.form.fullLegalName'),"maxlength":"64","minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.fullLegalName),callback:function ($$v) {_vm.$set(_vm.form, "fullLegalName", $$v)},expression:"form.fullLegalName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('idInfoModal.form.dateOfBirth'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-date-birth',{attrs:{"required":"","error":errors[0]},model:{value:(_vm.form.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.form, "dateOfBirth", $$v)},expression:"form.dateOfBirth"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('idInfoModal.form.idType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.idTypes,"label":_vm.$t('idInfoModal.form.idType'),"placeholder":_vm.$t('form.placeholders.select'),"filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.idType),callback:function ($$v) {_vm.$set(_vm.form, "idType", $$v)},expression:"form.idType"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('idInfoModal.form.governmentIssuedIdNumber'),"rules":"required|min:2|max:128|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-input',{attrs:{"label":_vm.$t('idInfoModal.form.governmentIssuedIdNumber'),"maxlength":"64","minlength":"2","required":"","error":errors[0]},model:{value:(_vm.form.governmentIssuedIdNumber),callback:function ($$v) {_vm.$set(_vm.form, "governmentIssuedIdNumber", $$v)},expression:"form.governmentIssuedIdNumber"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('idInfoModal.form.idNumberIssuingCountry'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.countries,"label":_vm.$t('idInfoModal.form.idNumberIssuingCountry'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"iso","filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.issuingCountryIsoCode),callback:function ($$v) {_vm.$set(_vm.form, "issuingCountryIsoCode", $$v)},expression:"form.issuingCountryIsoCode"}})]}}],null,true)}),_c('validation-provider',{attrs:{"tag":"div","name":_vm.$t('idInfoModal.form.nationality'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ui-select',{attrs:{"items":_vm.nationalities,"label":_vm.$t('idInfoModal.form.nationality'),"placeholder":_vm.$t('form.placeholders.select'),"label-key":"name","value-key":"id","filterable":"","required":"","error":errors[0]},model:{value:(_vm.form.nationalityId),callback:function ($$v) {_vm.$set(_vm.form, "nationalityId", $$v)},expression:"form.nationalityId"}})]}}],null,true)})],1),_c('div',{staticClass:"id-info-modal__explanation"},[_c('p',[_vm._v(_vm._s(_vm.$t('idInfoModal.explanation.question')))]),_c('p',[_vm._v(_vm._s(_vm.$t('idInfoModal.explanation.answer')))]),_c('p',[_vm._v(_vm._s(_vm.$t('idInfoModal.explanation.request')))])]),_c('div',{staticClass:"id-info-modal__footer"},[_c('ui-button',{staticClass:"id-info-modal__btn",attrs:{"disabled":_vm.loading,"round":"","type":"primary","native-type":"button","is-loading":_vm.loading},on:{"click":function($event){return _vm.submit(validate)}}},[_vm._v(" "+_vm._s(_vm.$t('form.buttons.ok'))+" ")])],1)]}}])})]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }