<template>
  <div class="bottom-sheet-confirmation">
    <div class="bottom-sheet-confirmation__text">
      <slot />
    </div>
    <new-button
      @click="$emit('confirm')"
    >
      {{ confirmText }}
    </new-button>
    <new-button
      type="ghost"
      @click="$router.push({ name: 'logout' })"
    >
      Exit App
    </new-button>
  </div>
</template>

<script>
export default {
  name: 'BottomSheetConfirmation',

  props: {
    confirmText: {
      type: String,
      default: 'Confirm',
    },
  },
};
</script>
