<template>
  <validation-observer v-slot="{ reset, handleSubmit }">
    <ui-modal
      class="checkout-card-confirm"
      ref="modal"
      :show="show"
      :is-loading="loading"
      @before-open="beforeOpen(reset)"
      @closed="closed"
      @input="$emit('input', $event)"
    >
      <template #header>
        <span class="checkout-card-confirm__title">
          {{ $t('checkout.title') }}
        </span>
      </template>
      <div class="checkout-card-confirm__body">
        <p class="checkout-card-confirm__info-title">
          {{ $t('checkout.subtitle') }}
        </p>
        <div class="checkout-card-confirm__code-input">
          <validation-provider
            tag="div"
            rules="required"
            #default="{ errors }"
          >
            <ui-otp-input
              v-model="code"
              ref="otpInput"
              :input-count="inputCount"
              :is-danger="errors[0] || otpCodeInvalid || otpCodeExpired"
              :error="errors[0] || otpCodeInvalid"
            />
          </validation-provider>
        </div>
        <div
          v-if="otpCodeExpired"
          class="checkout-card-confirm__expired-block"
        >
          <span
            class="checkout-card-confirm__expired-message"
          >
            {{ otpCodeExpired }}
          </span>
          <ui-button
            class="checkout-card-confirm__expired-link"
            type="primary"
            link
            @click="create(reset)"
          >
            {{ $t('form.buttons.resendVerificationCode') }}
          </ui-button>
        </div>
        <ui-button
          v-else-if="showResend"
          type="primary"
          link
          :disabled="showTimer"
          @click="create(reset)"
        >
          <span
            v-if="showTimer"
            class="checkout-card-confirm__timer"
          >
            {{ $t('form.buttons.resendVerificationCode') }} ({{ timer }})
          </span>
          <span
            v-else
            class="checkout-card-confirm__code-link"
          >
            {{ $t('form.buttons.resendVerificationCode') }}
          </span>
        </ui-button>
      </div>
      <template #footer="{ close }">
        <div class="checkout-card-confirm__actions">
          <ui-button
            class="checkout-card-confirm__button"
            size="sm"
            round
            outline
            @click="close"
          >
            {{ $t('form.buttons.cancel') }}
          </ui-button>
          <ui-button
            class="checkout-card-confirm__button"
            :class="{
              'checkout-card-confirm__button_primary': isOkButtonPrimary,
            }"
            size="sm"
            round
            outline
            @click="handleSubmit(() => apply(close))"
          >
            {{ $t('form.buttons.ok') }}
          </ui-button>
        </div>
      </template>
    </ui-modal>
  </validation-observer>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';
import Timer from '@/class/Timer';
import resetMixin from '@/mixins/reset-mixin';
import getErrorByCode from '@/utils/getErrorByCode';
import CARD_TYPES from '@/config/cardTypes';

const USER_ATTEMPTS = 5;
const TIMER_DURATION = 1; /* 1 minutes */

export default {
  name: 'CheckoutOtpModal',

  model: {
    prop: 'show',
    event: 'input',
  },

  mixins: [
    resetMixin(() => ({
      inputCount: 6,
      isOkButtonPrimary: false,
      loading: true,
      timer: null,
      confirmationId: '',
      code: '',
      otpCodeInvalid: '',
      otpCodeExpired: '',
      attemptsCount: 0,
    })),
  ],

  props: {
    show: Boolean,
    userId: String,
    card: Object,
    cardType: String,
  },

  computed: {
    ...mapState('user', [
      'location',
    ]),
    cardId() {
      return this.card?.id;
    },
    showTimer() {
      return !!this.timer;
    },
    showResend() {
      return this.confirmationId;
    },
  },

  watch: {
    code: {
      immediate: false,
      handler(value) {
        this.isOkButtonPrimary = (value.length === this.inputCount);
        this.otpCodeInvalid = '';
        this.otpCodeExpired = '';
      },
    },
    attemptsCount: {
      immediate: false,
      handler(value) {
        if (value === USER_ATTEMPTS) {
          this.$emit('failed', {
            type: 'warning',
            title: this.$t('payoutCard.attempts.title'),
            message: this.$t('payoutCard.attempts.message'),
            noButton: true,
          });
          this.$refs.modal.close();
        }
      },
    },
  },

  beforeDestroy() {
    this.stopTimer();
  },

  methods: {
    clearInput() {
      this.$refs.otpInput.clearInput();
    },
    closed() {
      this.$emit('closed');
      this.stopTimer();
    },
    startTimer() {
      this.timer = new Timer(TIMER_DURATION * 60 * 1000, () => {
        this.stopTimer();
      });
    },
    stopTimer() {
      if (this.timer) {
        this.timer.stop();
        this.timer = null;
      }
    },
    beforeOpen(validatorReset) {
      this.reset();
      this.create(validatorReset);
    },
    async create(validatorReset) {
      validatorReset();
      this.clearInput();
      this.attemptsCount = 0;
      this.otpCodeExpired = '';

      this.loading = true;

      const method = this.cardType === CARD_TYPES.CORPORATE
        ? 'createCorporateCardConfirm'
        : 'createPersonalCardConfirm';

      const query = this.cardType === CARD_TYPES.CORPORATE
        ? { workplaceId: this.location.id }
        : { userId: this.userId };

      try {
        const {
          data: {
            id,
          },
        } = await api.checkout[method]({
          ...query,
          cardId: this.cardId,
        });

        this.startTimer();
        this.confirmationId = id;
      } catch (e) {
        this.$showServerError(e);

        this.$emit('input', false);
      } finally {
        this.loading = false;
      }
    },
    async apply(close) {
      this.otpCodeInvalid = '';
      this.loading = true;

      const method = this.cardType === CARD_TYPES.CORPORATE
        ? 'checkCorporateCardConfirm'
        : 'checkPersonalCardConfirm';

      const query = this.cardType === CARD_TYPES.CORPORATE
        ? { workplaceId: this.location.id }
        : { userId: this.userId };

      try {
        await api.checkout[method]({
          ...query,
          cardId: this.cardId,
          confirmationId: this.confirmationId,
          code: this.code,
        });

        this.stopTimer();
        this.$emit('confirmed');
        close();
      } catch (e) {
        const errorCode = e?.response?.data?.errorCode;

        if (errorCode === 422203) {
          this.$emit('failed', {
            title: this.$t('payoutCard.invalid.title'),
            message: this.$t('payoutCard.invalid.message'),
            noButton: false,
          });

          close();
        } else if (errorCode === 422012) {
          this.otpCodeInvalid = getErrorByCode(errorCode).message;
        } else if (errorCode === 422013) {
          this.otpCodeExpired = getErrorByCode(errorCode).message;
        } else {
          this.$showServerError(e);
        }
      } finally {
        this.loading = false;
        this.attemptsCount += 1;
      }
    },
  },
};
</script>
