<template>
  <ui-avatar
    :image="imageUrl"
    :is-loading="loading"
    :aspect-ratio="0"
    crop-title="Upload image"
    class="id-info-modal__avatar"
    @save="uploadDocumentPhoto"
  />
</template>

<script>
import api from '@/api';

export default {
  name: 'IdPhotoUploader',

  props: {
    userId: {
      type: String,
      required: true,
    },
    side: {
      type: String,
      default: 'FRONT',
      validator: (value) => {
        return ['FRONT', 'BACK'].includes(value);
      },
    },
  },

  data() {
    return {
      loading: false,
      imageFile: null,
      imageUrl: null,
    };
  },

  methods: {
    async uploadDocumentPhoto(file) {
      this.imageFile = file;
      this.imageUrl = URL.createObjectURL(file);
      try {
        this.loading = true;
        const { data } = await api.user.sendAdditionalInfoScan({
          id: this.userId,
          file: this.imageFile,
          side: this.side,
        });
        this.$emit('set-image-id', data.id);
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
