<template>
  <div class="checkout-card-status">
    <div
      v-if="currentStatusData"
      :class="`checkout-card-status__${currentStatusData.type}`"
    >
      <ui-icon
        :name="currentStatusData.icon"
        :size="currentStatusData.iconSize"
      />
      <div
        :class="`checkout-card-status__${currentStatusData.type}-text`"
        v-html="currentStatusData.message"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutCardStatus',

  props: {
    status: {
      typeof: String,
      required: true,
    },
  },

  computed: {
    statusesData() {
      return [
        {
          status: 'DRAFT',
          type: 'error',
          icon: 'card-status-error',
          iconSize: '20px',
          message: this.$t('payoutCard.status.draft'),
        },
        {
          status: 'ACTIVE',
          type: 'success',
          icon: 'check',
          iconSize: '14px',
          message: this.$t('payoutCard.status.active'),
        },
        {
          status: 'PENDING',
          type: 'warning',
          icon: 'card-status-warning',
          iconSize: '20px',
          message: this.$t('payoutCard.status.pending'),
        },
        {
          status: 'PENDING_VERIFICATION',
          type: 'warning',
          icon: 'card-status-warning',
          iconSize: '20px',
          message: this.$t('payoutCard.status.pending-verification'),
        },
        {
          status: 'BLOCKED',
          type: 'error',
          icon: 'card-status-error',
          iconSize: '20px',
          message: this.$t('payoutCard.status.blocked'),
        },
        {
          status: 'NO_PROFILE_DATA',
          type: 'error',
          icon: 'card-status-error',
          iconSize: '20px',
          message: this.$t('payoutCard.status.noData'),
        },
      ];
    },
    currentStatusData() {
      return this.statusesData.find(({ status }) => status === this.status);
    },
  },
};
</script>
