<template>
  <ui-modal
    show
    no-footer
    class="id-info-modal"
    @closed="setVisibilityRequestIdModal(false)"
  >
    <div class="id-info-modal__content">
      <div class="id-info-modal__header">
        <div class="id-info-modal__title">
          {{ $t('idInfoModal.title') }}
        </div>
      </div>
      <p class="id-info-modal__message">
        {{ $t('idInfoModal.message') }}
      </p>
      <template v-if="!showIdInfoForm">
        <ui-icon
          class="id-info-modal__icon"
          name="filling-form"
          :size="100"
        />
        <div class="id-info-modal__footer">
          <ui-button
            round
            type="primary"
            native-type="button"
            class="id-info-modal__btn"
            @click="openIdInfoForm"
          >
            {{ $t('form.buttons.ok') }}
          </ui-button>
        </div>
      </template>
      <template v-else>
        <validation-observer v-slot="{ validate }">
          <div class="id-info-modal__form">
            <id-info-photos
              :validation-error="photoValidationError"
            >
              <id-photo-uploader
                side="FRONT"
                :user-id="userId"
                @set-image-id="setImageId($event, 'front')"
              />
              <id-photo-uploader
                side="BACK"
                :user-id="userId"
                @set-image-id="setImageId($event, 'back')"
              />
            </id-info-photos>
            <validation-provider
              tag="div"
              :name="$t('idInfoModal.form.fullLegalName')"
              rules="required|min:2|max:256"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.fullLegalName"
                :label="$t('idInfoModal.form.fullLegalName')"
                maxlength="64"
                minlength="2"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('idInfoModal.form.dateOfBirth')"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-date-birth
                v-model="form.dateOfBirth"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('idInfoModal.form.idType')"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.idType"
                :items="idTypes"
                :label="$t('idInfoModal.form.idType')"
                :placeholder="$t('form.placeholders.select')"
                filterable
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('idInfoModal.form.governmentIssuedIdNumber')"
              rules="required|min:2|max:128|numeric|min_value:1"
              v-slot="{ errors }"
            >
              <ui-input
                v-model="form.governmentIssuedIdNumber"
                :label="$t('idInfoModal.form.governmentIssuedIdNumber')"
                maxlength="64"
                minlength="2"
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('idInfoModal.form.idNumberIssuingCountry')"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.issuingCountryIsoCode"
                :items="countries"
                :label="$t('idInfoModal.form.idNumberIssuingCountry')"
                :placeholder="$t('form.placeholders.select')"
                label-key="name"
                value-key="iso"
                filterable
                required
                :error="errors[0]"
              />
            </validation-provider>
            <validation-provider
              tag="div"
              :name="$t('idInfoModal.form.nationality')"
              rules="required"
              v-slot="{ errors }"
            >
              <ui-select
                v-model="form.nationalityId"
                :items="nationalities"
                :label="$t('idInfoModal.form.nationality')"
                :placeholder="$t('form.placeholders.select')"
                label-key="name"
                value-key="id"
                filterable
                required
                :error="errors[0]"
              />
            </validation-provider>
          </div>
          <div class="id-info-modal__explanation">
            <p>{{ $t('idInfoModal.explanation.question') }}</p>
            <p>{{ $t('idInfoModal.explanation.answer') }}</p>
            <p>{{ $t('idInfoModal.explanation.request') }}</p>
          </div>
          <div class="id-info-modal__footer">
            <ui-button
              :disabled="loading"
              round
              type="primary"
              native-type="button"
              class="id-info-modal__btn"
              :is-loading="loading"
              @click="submit(validate)"
            >
              {{ $t('form.buttons.ok') }}
            </ui-button>
          </div>
        </validation-observer>
      </template>
    </div>
  </ui-modal>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import api from '@/api';
import toSQLDate from '@/utils/toSQLDate';
import { DOCUMENT_TYPES } from '@/config/documentTypes';
import UiModal from '@/components/UI/Modal.vue';
import IdPhotoUploader from '@/components/IdInfo/IdPhotoUploader.vue';
import IdInfoPhotos from '@/components/IdInfo/IdInfoPhotos.vue';

export default {
  name: 'IdInfoModal',

  components: {
    IdInfoPhotos,
    UiModal,
    IdPhotoUploader,
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showIdInfoForm: false,
      loading: false,
      imageFile: null,
      imageId: {
        front: null,
        back: null,
      },
      form: {
        fullLegalName: '',
        dateOfBirth: '',
        idType: '',
        governmentIssuedIdNumber: '',
        issuingCountryIsoCode: '',
        nationalityId: '',
      },
      photoValidationError: false,
      idTypes: DOCUMENT_TYPES,
      nationalities: [],
    };
  },

  computed: {
    countries() {
      return this.$getCountries({ i18n: true });
    },
    atLeastOnePhotoUploaded() {
      return this.imageId.front || this.imageId.back;
    },
  },

  methods: {
    ...mapMutations({
      setVisibilityRequestIdModal: 'user/setVisibilityRequestIdModal',
    }),
    ...mapActions('user', [
      'loadUser',
    ]),
    async submit(validate) {
      if (!this.atLeastOnePhotoUploaded) {
        this.photoValidationError = true;
      }

      if (!await validate() || this.photoValidationError) {
        return;
      }

      try {
        this.loading = true;
        const requestData = {
          frontSideDocumentId: this.imageId.front,
          backSideDocumentId: this.imageId.back,
          dateOfBirth: toSQLDate(this.form.dateOfBirth),
          fullLegalName: this.form.fullLegalName,
          idType: this.form.idType,
          governmentIssuedIdNumber: this.form.governmentIssuedIdNumber,
          issuingCountryIsoCode: this.form.issuingCountryIsoCode,
          nationality: {
            id: this.form.nationalityId,
          },
        };
        await api.user.sendAdditionalInfo({ id: this.userId, ...requestData });
        await this.loadUser();
        this.setVisibilityRequestIdModal(false);
        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading = false;
      }
    },
    openIdInfoForm() {
      this.showIdInfoForm = true;
    },
    setImageId(id, side) {
      this.imageId[side] = id;
      this.photoValidationError = false;
    },
    async loadAllCountries() {
      try {
        const { data } = await api.handbook.getCountries();
        this.nationalities = data;
      } catch (e) {
        this.$showServerError(e);
      }
    },
  },
  created() {
    this.loadAllCountries();
  },
};
</script>
