<template>
  <div class="bottom-sheet-success">
    <div class="bottom-sheet-success__body">
      <ui-icon
        name="check"
        size="14px"
        class="bottom-sheet-success__icon"
      />
      <div>
        <slot />
      </div>
    </div>
    <new-button
      @click="$emit('submit')"
    >
      Ok
    </new-button>
  </div>
</template>

<script>
export default {
  name: 'BottomSheetSuccess',
};
</script>
