<template>
  <footer class="footer" :class="contented ? '' : 'footer-black footer-white'">
    <div class="container-fluid">
      <div :class="contented ? 'pull-left' : 'row'">
        <div :class="contented ? 'copyright pull-right' : 'credits ml-auto'">
          <div class="copyright">
            Copyright &copy; <span>{{ getFullYear }}</span> EASYTIP
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {

  props: ['contented'],

  computed: {
    getFullYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
